// Form
// Most form styles are configured using Tailwind.config.js

.form-alert {
    @apply mb-12 p-6 bg-black;
}

.form-alert--success {
}

.form-alert--error {
}

.form-alert__text {
    @apply font-bold;
}

.form-alert__text:before {
    @apply inline-block mr-2 font-awesome font-normal font-bold;
}

.form-alert__text--success {
	@apply text-green-dark;
	&:before {
		content: "\f058";
	}
}

.form-alert__text--error {
    @apply text-white;
    &:before {
		content: "\f06a";
	}
}

.form-label {
    @extend .h4;
}

.form-input {
    @screen lg {
        @apply px-6 py-3;
    }
}


// Mark-up controlled by Freeform
.errors {
    color: #ff0000;
    @apply mt-2;
}

.errors li:before {
    content: "\f06a";
    @apply inline-block mr-2 font-awesome font-normal font-bold;
}


// Icons
.icon {
    @apply inline-block;
}

.icon--pin {
    margin-right: 14px;
}

.icon--user {
    margin-right: 12px;
}
