// Banner
.banner {
    @apply overflow-hidden relative;
}

.banner__inner {
    @apply pt-8;
    @screen md {
        @apply pt-14 pb-16;
    }
    @screen lg {
        @apply pt-12 pb-20;
    }
    @screen xl {
        @apply pt-18 pb-24;
    }
    @screen xxl {
        @apply pt-24 pb-30;
    }
    @screen xxxl {
        @apply pt-32 pb-40;
    }
}

.banner__image {
    width: calc(100% - 24px);
    mask-image: url('/img/mobile-blobs.svg');
    mask-repeat: no-repeat;
    mask-size: cover;
    mask-position: bottom left;
    @apply w-full ml-6 object-cover object-bottom;
    @screen md {
        width: 48%;
        @apply z-0 absolute top-0 right-0 h-full ml-0;
    }
    @screen xl {
        mask-image: url('/img/blobs.svg');
        width: 54%;
        max-width: 1280px;
    }
}

.h2.banner__title {
    @screen xl {
        font-size: 62px;
    }
    @screen xxl {
        font-size: 76px;
    }
}
