@charset "UTF-8";
@tailwind base;
@tailwind components;
/* purgecss start ignore */
.banner {
  @apply overflow-hidden relative;
}

.banner__inner {
  @apply pt-8;
}
@screen md {
  .banner__inner {
    @apply pt-14 pb-16;
  }
}
@screen lg {
  .banner__inner {
    @apply pt-12 pb-20;
  }
}
@screen xl {
  .banner__inner {
    @apply pt-18 pb-24;
  }
}
@screen xxl {
  .banner__inner {
    @apply pt-24 pb-30;
  }
}
@screen xxxl {
  .banner__inner {
    @apply pt-32 pb-40;
  }
}

.banner__image {
  width: calc(100% - 24px);
  mask-image: url("/img/mobile-blobs.svg");
  mask-repeat: no-repeat;
  mask-size: cover;
  mask-position: bottom left;
  @apply w-full ml-6 object-cover object-bottom;
}
@screen md {
  .banner__image {
    width: 48%;
    @apply z-0 absolute top-0 right-0 h-full ml-0;
  }
}
@screen xl {
  .banner__image {
    mask-image: url("/img/blobs.svg");
    width: 54%;
    max-width: 1280px;
  }
}

@screen xl {
  .h2.banner__title {
    font-size: 62px;
  }
}
@screen xxl {
  .h2.banner__title {
    font-size: 76px;
  }
}

@font-face {
  font-family: "Dala Moa Web";
  src: url("/webfonts/DalaMoa-Medium-Web.woff2") format("woff2"), url("/webfonts/DalaMoa-Medium-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}
.form-alert {
  @apply mb-12 p-6 bg-black;
}

.form-alert__text {
  @apply font-bold;
}

.form-alert__text:before {
  @apply inline-block mr-2 font-awesome font-normal font-bold;
}

.form-alert__text--success {
  @apply text-green-dark;
}
.form-alert__text--success:before {
  content: "";
}

.form-alert__text--error {
  @apply text-white;
}
.form-alert__text--error:before {
  content: "";
}

@screen lg {
  .form-input {
    @apply px-6 py-3;
  }
}

.errors {
  color: #ff0000;
  @apply mt-2;
}

.errors li:before {
  content: "";
  @apply inline-block mr-2 font-awesome font-normal font-bold;
}

.icon {
  @apply inline-block;
}

.icon--pin {
  margin-right: 14px;
}

.icon--user {
  margin-right: 12px;
}

@responsive {
  .row {
    max-width: 1364px;
    @apply flex flex-wrap mx-auto;
  }
}
@responsive {
  .column {
    @apply w-full px-6;
  }
  @screen md {
    .column {
      @apply px-7;
    }
  }
  @screen lg {
    .column {
      @apply px-8;
    }
  }
}
@screen md {}
@screen lg {}

.h1,
.typography h1 {
  font-size: 42px;
  line-height: 1;
  @apply font-serif;
}
@screen md {
  .h1,
.typography h1 {
    @apply whitespace-pre-line;
  }
}
@screen lg {
  .h1,
.typography h1 {
    font-size: 64px;
  }
}
@screen xl {
  .h1,
.typography h1 {
    font-size: 86px;
  }
}
@screen xxl {
  .h1,
.typography h1 {
    font-size: 98px;
  }
}

.h1--margin {
  @apply mb-4;
}
@screen lg {
  .h1--margin {
    @apply mb-6;
  }
}
@screen xl {
  .h1--margin {
    @apply mb-8;
  }
}
@screen xxl {
  .h1--margin {
    @apply mb-10;
  }
}

.h2 {
  font-size: 34px;
  line-height: 1;
  @apply font-serif;
}
@screen lg {
  .h2 {
    font-size: 44px;
  }
}
@screen xl {
  .h2 {
    font-size: 52px;
  }
}
@screen xxl {
  .h2 {
    font-size: 62px;
  }
}

.typography h2,
.h3 {
  font-size: 20px;
  @apply font-semibold;
}
@screen lg {
  .typography h2,
.h3 {
    font-size: 26px;
  }
}

.h4, .newsletter__input, .form-label,
.typography h3,
.typography h4,
.typography th {
  @apply font-bold uppercase;
}
@screen xxl {
  .h4, .newsletter__input, .form-label,
.typography h3,
.typography h4,
.typography th {
    font-size: 18px;
  }
}

.h4--border,
.typography h3 {
  @apply mb-2 pb-2 border-b border-solid border-black;
}
@screen lg {
  .h4--border,
.typography h3 {
    @apply mb-3 pb-3;
  }
}

.leader,
.typography .leader {
  font-size: 18px;
}
@screen md {
  .leader,
.typography .leader {
    font-size: 20px;
  }
}
@screen xl {
  .leader,
.typography .leader {
    font-size: 22px;
  }
}
@screen xxl {
  .leader,
.typography .leader {
    font-size: 24px;
  }
}

.content {
  @apply mb-4;
}
@screen lg {
  .content {
    @apply mb-6;
  }
}

.typography h2 {
  @apply mb-2;
}
@screen lg {
  .typography h2 {
    @apply mb-4;
  }
}
.typography p, .typography ul, .typography ol, .typography table {
  @apply mb-4;
}
@screen lg {
  .typography p, .typography ul, .typography ol, .typography table {
    @apply mb-6;
  }
}
.typography p:last-child, .typography ul:last-child, .typography ol:last-child, .typography table:last-child {
  @apply mb-0;
}
.typography ul li {
  position: relative;
  @apply pl-5;
}
@screen lg {
  .typography ul li {
    @apply pl-7;
  }
}
.typography ul li:before {
  content: "";
  width: 4px;
  height: 4px;
  top: 12px;
  @apply block absolute left-0 rounded-full bg-black;
}
@screen lg {
  .typography ul li:before {
    width: 5px;
    height: 5px;
    top: 14px;
  }
}
.typography li:not(:last-child) {
  @apply pb-1;
}
.typography ol {
  @apply ml-5;
}
.typography ol li {
  @apply list-decimal list-outside pl-1;
}
@screen lg {
  .typography ol li {
    @apply pl-2;
  }
}
.typography table {
  @apply w-full;
}
.typography table th {
  @apply bg-black text-white text-left;
}
.typography table th, .typography table td {
  @apply px-2 py-1 border border-solid border-black;
}
@screen md {
  .typography table th, .typography table td {
    @apply px-4 py-2;
  }
}
@screen xl {
  .typography table th, .typography table td {
    @apply px-6;
  }
}
.typography * + table {
  @apply mt-6;
}
@screen lg {
  .typography * + table {
    @apply mt-8;
  }
}
.typography a {
  @apply underline;
}
.typography a:hover {
  @apply no-underline;
}

.typography--alternative .leader + h3,
.typography--alternative p + h3 {
  @apply mt-8;
}
@screen lg {
  .typography--alternative .leader + h3,
.typography--alternative p + h3 {
    @apply mt-10;
  }
}

.typography + .download-link {
  @apply mt-8;
}
@screen lg {
  .typography + .download-link {
    @apply mt-10;
  }
}

.gallery {
  width: 100%;
  margin: -0.5px;
}

.gallery__item {
  width: calc(50% - 1px);
  margin: 0.5px;
}
@screen md {
  .gallery__item {
    width: calc(33.33% - 1px);
  }
}

.square-grid {
  @apply w-full flex flex-wrap;
}
@screen md {
  .square-grid {
    margin-top: 1px;
  }
}

.square-grid__item {
  @apply relative border border-solid border-black;
  margin-top: -1px;
  margin-left: -1px;
}
.square-grid--black .square-grid__item {
  @apply bg-black text-white border-white;
}

.square-grid__item:after {
  content: "";
  padding-bottom: 100%;
  @apply block;
}

.square-grid__inner {
  @apply absolute w-full h-full top-0 left-0 p-8;
}
@screen xl {
  .square-grid__inner {
    @apply p-10;
  }
}
.square-grid--elements .square-grid__inner {
  @apply flex items-center justify-center p-4;
}
.square-grid__inner .text-green-dark {
  @apply transition duration-300 ease-in-out;
}
.square-grid__inner:hover .text-green-dark {
  @apply text-white;
}

.square-grid__title {
  font-size: 20px;
  line-height: 1.2;
  @apply font-semibold mb-2;
}
@screen md {
  .square-grid__title {
    font-size: 32px;
    @apply mb-4;
  }
}
@screen lg {
  .square-grid__title {
    font-size: 44px;
  }
}

.square-grid__text {
  @apply hidden w-full opacity-0 text-white transition-opacity duration-300 ease-in-out;
}
@screen md {
  .square-grid__text {
    @apply block;
  }
}
.square-grid__item:hover .square-grid__text {
  @apply opacity-100;
}

.list__title {
  font-size: 18px;
  line-height: 1.2;
  @apply font-semibold mb-1;
}
@screen md {
  .list__title {
    font-size: 20px;
  }
}
@screen xl {
  .list__title {
    font-size: 22px;
  }
}

.list__link {
  @apply block py-4 border-b border-solid border-black;
}
@screen md {
  .list__link {
    @apply py-6;
  }
}

.list__text {
  @apply transition duration-300 ease-in-out;
}
.list__link:hover .list__text {
  @apply text-green-dark;
}

.short-list__item {
  @apply flex flex-wrap justify-between py-2 border-b border-solid border-black;
}
@screen lg {
  .short-list__item {
    @apply py-4;
  }
}

.short-list__item:first-child {
  @apply border-t;
}

.padding {
  padding-top: 50px;
  padding-bottom: 50px;
}
@screen lg {
  .padding {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@screen xxl {
  .padding {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.margin {
  margin-top: 50px;
  margin-bottom: 50px;
}
@screen lg {
  .margin {
    margin-top: 70px;
    margin-bottom: 70px;
  }
}
@screen xxl {
  .margin {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

.margin--sm {
  margin-top: 30px;
  margin-bottom: 30px;
}
@screen md {
  .margin--sm {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@screen lg {
  .margin--sm {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.padding--sm {
  padding-top: 30px;
  padding-bottom: 30px;
}
@screen md {
  .padding--sm {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@screen lg {
  .padding--sm {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.staff {
  @apply my-4;
}
@screen lg {
  .staff {
    @apply my-6;
  }
}

.staff__title {
  @apply block relative pb-5 border-b border-solid border-black;
}

.staff__title:hover {
  @apply cursor-pointer;
}

.staff__title:before,
.staff__title:after {
  content: "";
  width: 18px;
  height: 4px;
  bottom: 12%;
  right: 0;
  transform: translateY(-50%);
  @apply block absolute bg-black transition-all duration-300 ease-in-out;
}
@screen lg {
  .staff__title:before,
.staff__title:after {
    width: 20px;
    height: 4px;
  }
}

.staff__title:after {
  transform: translateY(-50%) rotate(90deg);
}

.staff__title.is-active:after {
  transform: translateY(-50%) rotate(0deg);
}

.staff__content {
  max-height: 0;
  transition-property: opacity, height, max-height;
  @apply overflow-hidden opacity-0 duration-300 ease-in-out;
}
@screen xl {
  .staff__content {
    font-size: 18px;
  }
}

.staff__content.is-active {
  max-height: 1000px;
  @apply opacity-100 pt-4;
}
@screen lg {
  .staff__content.is-active {
    @apply pt-6;
  }
}

/* purgecss end ignore */
html, body {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, input, select, textarea {
  font-size: 16px;
  line-height: 1.5;
}
@screen md {
  body, input, select, textarea {
    font-size: 18px;
  }
}
@screen xxl {
  body, input, select, textarea {
    font-size: 20px;
  }
}

a,
button {
  @apply transition duration-300 ease-in-out;
}

.responsive-video {
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
  @apply relative;
}

.responsive-video iframe {
  @apply absolute top-0 left-0 w-full h-full;
}

.newsletter {
  @apply bg-black p-4;
}
@screen lg {
  .newsletter {
    @apply p-8;
  }
}

.newsletter__input {
  line-height: 1;
  @apply w-full px-6 py-3 bg-black text-white border border-solid border-white;
}
@screen lg {
  .newsletter__input {
    @apply px-8 py-4;
  }
}
.newsletter__input::placeholder {
  @apply opacity-100 text-white;
}

[v-cloak] {
  display: none !important;
}

.xs\:h-40 {
  height: 10rem;
}
@media (max-width: 360px) {
  .xs\:h-40 {
    height: 6rem;
  }
}