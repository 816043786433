// Layout
// Rows and columns for easier margin/spacing management

@responsive {
    .row {
        max-width: 1364px;
        @apply flex flex-wrap mx-auto;
    }
}

@responsive {
    .column {
        @apply w-full px-6;
        @screen md {
            @apply px-7;
        }
        @screen lg {
            @apply px-8;
        }
    }
}
