// Typography
.h1,
.typography h1 {
    font-size: 42px;
    line-height: 1;
    @apply font-serif;
    @screen md {
        @apply whitespace-pre-line;
    }
    @screen lg {
        font-size: 64px;
    }
    @screen xl {
        font-size: 86px;
    }
    @screen xxl {
        font-size: 98px;
    }
}

.h1--margin {
    @apply mb-4;
    @screen lg {
        @apply mb-6;
    }
    @screen xl {
        @apply mb-8;
    }
    @screen xxl {
        @apply mb-10;
    }
}

.h2 {
    font-size: 34px;
    line-height: 1;
    @apply font-serif;

    @screen lg {
        font-size: 44px;
    }
    @screen xl {
        font-size: 52px;
    }
    @screen xxl {
        font-size: 62px;
    }
}

.typography h2,
.h3 {
    font-size: 20px;
    @apply font-semibold;
    @screen lg {
        font-size: 26px;
    }
}

.h4,
.typography h3,
.typography h4,
.typography th {
    @apply font-bold uppercase;
    @screen xxl {
        font-size: 18px;
    }
}

.h4--border,
.typography h3 {
    @apply mb-2 pb-2 border-b border-solid border-black;
    @screen lg {
        @apply mb-3 pb-3;
    }
}

.leader,
.typography .leader {
    font-size: 18px;
    @screen md {
        font-size: 20px;
    }
    @screen xl {
        font-size: 22px;
    }
    @screen xxl {
        font-size: 24px;
    }
}

.content {            // Same as paragraph margins
    @apply mb-4;
    @screen lg {
        @apply mb-6;
    }
}


// Redactor (WYSIWYG) content styles
.typography {
    h2 {
        @apply mb-2;
        @screen lg {
            @apply mb-4;
        }
    }
    p, ul, ol, table {
        @apply mb-4;
    	@screen lg {
    		@apply mb-6;
    	}
        &:last-child {
            @apply mb-0;
        }
    }
	ul li {
        position: relative;
        @apply pl-5;
        @screen lg {
            @apply pl-7;
        }
        &:before {
            content: '';
            width: 4px;
            height: 4px;
            top: 12px;
            @apply block absolute left-0 rounded-full bg-black;
            @screen lg {
                width: 5px;
                height: 5px;
                top: 14px;
            }
    	}
    }
    li:not(:last-child) {
        @apply pb-1;
    }
    ol {
        @apply ml-5;
    }
	ol li {
        @apply list-decimal list-outside pl-1;
        @screen lg {
            @apply pl-2;
        }
	}
    table {
        @apply w-full;
        th {
            @apply bg-black text-white text-left;
        }
        th, td {
            @apply px-2 py-1 border border-solid border-black;
            @screen md {
                @apply px-4 py-2;
            }
            @screen xl {
                @apply px-6;
            }
        }
    }
    * + table {
        @apply mt-6;
        @screen lg {
            @apply mt-8;
        }
    }
    img {
    }
    a {
        @apply underline;
        &:hover {
            @apply no-underline;
        }
    }
}

.typography--alternative {
    .leader + h3,
    p + h3 {
        @apply mt-8;
        @screen lg {
            @apply mt-10;
        }
    }
}

.typography + .download-link {
    @apply mt-8;
    @screen lg {
        @apply mt-10;
    }
}
