// Sections
// Spacing and general styles for sections


// Gutters - default
$section-gutters: (
    lg: 70px,
    xxl: 80px
);

// Gutters - slightly less than default
$section-gutters-sm: (
    md: 30px,
    lg: 50px
);


.section {
}

.padding {
    padding-top: 50px;
	padding-bottom: 50px;
	@each $breakpoint, $value in $section-gutters {
		@screen #{$breakpoint} {
			padding-top: $value;
			padding-bottom: $value;
		}
	}
}

.margin {
	margin-top: 50px;
	margin-bottom: 50px;
	@each $breakpoint, $value in $section-gutters {
		@screen #{$breakpoint} {
			margin-top: $value;
			margin-bottom: $value;
		}
	}
}

.margin--sm {
	margin-top: 30px;
	margin-bottom: 30px;
	@each $breakpoint, $value in $section-gutters-sm {
		@screen #{$breakpoint} {
			margin-top: $value;
			margin-bottom: $value;
		}
	}
}

.padding--sm {
	padding-top: 30px;
	padding-bottom: 30px;
	@each $breakpoint, $value in $section-gutters-sm {
		@screen #{$breakpoint} {
			padding-top: $value;
			padding-bottom: $value;
		}
	}
}
