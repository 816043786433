// Square grids
.square-grid {
    @apply w-full flex flex-wrap;
    @screen md {
        margin-top: 1px;
    }
}

.square-grid__item {
    @apply relative border border-solid border-black;
    margin-top: -1px;
    margin-left: -1px;
    .square-grid--black & {
        @apply bg-black text-white border-white;
    }
}

.square-grid__item:after {
    content: '';
    padding-bottom: 100%;
    @apply block;
}

.square-grid__inner {
    @apply absolute w-full h-full top-0 left-0 p-8;
    @screen xl {
        @apply p-10;
    }
    .square-grid--elements & {
        @apply flex items-center justify-center p-4;
    }
    .text-green-dark {
        @apply transition duration-300 ease-in-out;
    }
    &:hover .text-green-dark {
        @apply text-white;
    }
}

.square-grid__title {
    font-size: 20px;
    line-height: 1.2;
    @apply font-semibold mb-2;
    @screen md {
        font-size: 32px;
        @apply mb-4;
    }
    @screen lg {
        font-size: 44px;
    }
}

.square-grid__text {
    @apply hidden w-full opacity-0 text-white transition-opacity duration-300 ease-in-out;
    @screen md {
        @apply block;
    }
    .square-grid__item:hover & {
        @apply opacity-100;
    }
}

// Lists
.list {
}

.list__item {
}

.list__title {
    font-size: 18px;
    line-height: 1.2;
    @apply font-semibold mb-1;
    @screen md {
        font-size: 20px;
    }
    @screen xl {
        font-size: 22px;
    }
}

.list__link {
    @apply block py-4 border-b border-solid border-black;
    @screen md {
        @apply py-6;
    }
}

.list__text {
    @apply transition duration-300 ease-in-out;
    .list__link:hover & {
        @apply text-green-dark;
    }
}

// Short lists
.short-list__item {
    @apply flex flex-wrap justify-between py-2 border-b border-solid border-black;
    @screen lg {
        @apply py-4;
    }
}

.short-list__item:first-child {
    @apply border-t;
}
