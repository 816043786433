// Staff
.staff {
     @apply my-4;
     @screen lg {
         @apply my-6;
     }
}

.staff__title {
	@apply block relative pb-5 border-b border-solid border-black;
}

.staff__title:hover {
    @apply cursor-pointer;
}

.staff__title:before,
.staff__title:after  {
    content: '';
    width: 18px;
    height: 4px;
    bottom: 12%;
    right: 0;
    transform: translateY(-50%);
    @apply block absolute bg-black transition-all duration-300 ease-in-out;
    @screen lg {
        width: 20px;
        height: 4px;
    }
}

.staff__title:after {
    transform: translateY(-50%) rotate(90deg);
}

.staff__title.is-active:after {
    transform: translateY(-50%) rotate(0deg);
}

.staff__content {
    max-height: 0;
    transition-property: opacity, height, max-height;
	@apply overflow-hidden opacity-0 duration-300 ease-in-out;
    @screen xl {
        font-size: 18px;
    }
}

.staff__content.is-active {
    max-height: 1000px;
    @apply opacity-100 pt-4;
    @screen lg {
        @apply pt-6;
    }
}
