// Gallery
.gallery {
    width: 100%;
    margin: -0.5px;
}

.gallery__item {
    width: calc(50% - 1px);
    margin: 0.5px;
    @screen md {
        width: calc(33.33% - 1px);
    }
}
